import React from 'react';


const ApplyJob = () => {
    return (
        <div id='applyjobs' className='backgroundColor'>
            <h1 className='fontStyleH1'>Apply Job</h1>
            <hr className='separator'></hr>
            <div className='cardFlexItemJob'>
                For Job Applications, Please send your updated resume to<br/>
                <b>info@tbdchefconsultancy.com</b><br/> 
                with the subject line as 
                <br/>
                <b>Job Appplication</b>
                <br/>
                <br/>
            </div>
        </div>
    );
}

export default ApplyJob;