import React from 'react';
import Card from 'react-bootstrap/Card';


const News = () => {
    return(
          <div id='news'>
            <h1 className='fontStyleH1'>News</h1>
            <hr className='separator'></hr>
            <div className='flex-container'>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/industry_outlook_2023.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Award by Industry Outlook 2023</Card.Title>
                        <Card.Text>
                            Ranked among the Top 10 Food and Beverage consultants for the year 2023
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/industry_outlook_2024_1.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Award by Industry Outlook 2024 </Card.Title>
                        <Card.Text>
                            Ranked among the Top 10 Food and Beverage consultants for the year 2024
                        </Card.Text>
                    </Card.Body>
                </Card>                
            </div>
          </div>
          );
}

export default News;