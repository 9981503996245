import './App.css';
import Menu from './components/Menu';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import ApplyJob from './components/ApplyJob';
import Services from './components/Services';
import Home from './components/Home';
import Projects from './components/Projects';
import News from './components/News';


function App() {
  return (
    <div className="App">
      <Menu></Menu>
      <Home></Home>
      <Services></Services>
      <Projects></Projects>
      <AboutUs></AboutUs>
      <ContactUs></ContactUs>
      <ApplyJob></ApplyJob>
      <News></News>
    </div>
  );
}

export default App;
