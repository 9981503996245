import React from 'react';
import Card from 'react-bootstrap/Card';


const Services = () => {
    return(
          <div id='services'>
            <h1 className='fontStyleH1'>Services</h1>
            <hr className='separator'></hr>
            <div className='flex-container'>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/planning.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Concept development & Execution </Card.Title>
                        <Card.Text>
                            A structured way to develop an idea of a Hotel Restaurant & Café, Banquet hall, Tea Lounge, Bakery & Patisserie, QSR & Cloud Kitchen or Food factory with Large portfolio and determine what our customers & market is willing to buy from our services
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/menu.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Menu Engineering & development</Card.Title>
                        <Card.Text>
                            Menu Engineering includes perception, attention, emotion, and effect highlighted in Menu Designed. as well as managing overall food cost for contribution, the margin of every Recipe cost analysis, and pricing
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/kitchen_design.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Kitchen / BOH Design & Manufacturing Equipments</Card.Title>
                        <Card.Text>
                            Professional Kitchen Planning & Designing is a major concern before the execution of the kitchen starts. The kitchen's layout must ensure the best space utilization along with the easy movement of movable equipment in the kitchen and corridors. To ensure practical implementation of equipment in the space provided and follow the Govt Law as applicable
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/budget.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Project budget and operational budget</Card.Title>
                        <Card.Text>
                            Includes permanent assets, place, equipment, staffing required, and cost for the same
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/operations.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Operations set-up & implementation</Card.Title>
                        <Card.Text>
                            Includes operational requirement to run the show of service industry
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/requirement.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Recruitment, Tasting and demonstrations</Card.Title>
                        <Card.Text>
                            Include - hiring the talent form the market by choosing professionals, recipe trade test
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/sop.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Manuals and SOPs</Card.Title>
                        <Card.Text>
                            Standard operating processes and manuals to maintain company vision
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/audit.webp'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Operations audits & streamlining</Card.Title>
                        <Card.Text>
                            Any organization requires more efficient and effective role employing faster or simpler working methods to achieve the goals. We provide F&B consultancy for such purposes
                        </Card.Text>
                    </Card.Body>
                </Card>
                
            </div>
          </div>
          );
}

export default Services;