import React from 'react';


const Home = () => {
    return (
        <div id='home'>
            <div className='bg'>
            </div>
            <div className='introTextPosition'>
                <span>
                    <span className='companyName textGlow'>TBD Chef Consultancy</span><br/>
                    <span className='companyTagLine textGlow'>Happy & Speedy Kitchen Promised</span><br/>
                    <a href='#services'>
                        <i className="bi bi-chevron-double-down companyArrowDownLogo textGlow"></i>
                    </a>
                </span>
            </div>
        </div>
    );
}

export default Home;