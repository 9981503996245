import React from 'react';


const AboutUs = () => {
    return (
        <div id='aboutus'>
            <h1 className='fontStyleH1'>About Us</h1>
            <hr className='separator'></hr>
            <div className='about'>
                <div>
                    As a small brief to Techno Business Developer - TBD Chef Consultancy Services - Leading kitchen consultant known as TBD Holidays Private Limited, came into existence in 2018. TBD Holidays company focuses on how external engagements (alliances, partnerships, acquisitions) help a company achieve their business objectives, including entering to a new market, drive awareness of solution within a market, provide a complete solution including Equipment, software & assists with services for Menu Engineering, Commercial Kitchen Planning and Back of House facility Planning, setups, project execution, staffing and operational as per the requirement at the best possibilities. We are slowly becoming the top kitchen consultant in the Lucknow area. TBD Holidays has worked closely with hotel owners for over 18 years and understands the pain points involved in a project during its life cycle. TBD Holidays offers specialist business consultancy services to hotel and resort owners, Operators, Developers, Lenders, and Investors. What distinguishes us from other consultancies is having an experience of over 16 years as a chef, we know what it takes to make an efficient commercial kitchen. Our consultancy offers services to businesses of all sizes, from developing small boutique hotels to helping organizations with a large portfolio of hotels improve performance and keep ahead of the game. Our knowledge and expertise in making hotels and resorts successful cannot be matched elsewhere
                </div>
            </div>
        </div>
    );
}

export default AboutUs;