import React from 'react';
import Card from 'react-bootstrap/Card';


const Projects = () => {
    return(
          <div>
            <h1 id="projects" className='fontStyleH1'>Projects</h1>
            <hr className='separator'></hr>
            
            <div className='flex-container'>
                <Card className='cardFlexItem' href='https://www.instagram.com/cafedeleila/'>
                    <a href='https://www.instagram.com/cafedeleila/' target="_blank" rel="noreferrer">
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + '/cafe_de_leila.webp'} />
                    </a>
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>
                            <a href='https://www.instagram.com/cafedeleila/' target="_blank" rel="noreferrer">
                            <span>Cafe De Leila </span><i className="bi bi-instagram color-red"></i> 
                            </a><br/>
                        </Card.Title>
                        <Card.Text>
                            Designed the outdoor kitchen and bar area
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem' href='https://www.instagram.com/hotelgangamaiya/'>
                    <a href='https://www.instagram.com/hotelgangamaiya/' target="_blank" rel="noreferrer">
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + '/hotel_ganga_maiya.webp'} />
                    </a>
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>
                            <a href='https://www.instagram.com/hotelgangamaiya/' target="_blank" rel="noreferrer">
                            <span>Hotel Ganga Maiya </span><i className="bi bi-instagram color-red"></i> 
                            </a><br/>
                        </Card.Title>
                        <Card.Text>
                           Kitchen and BOH Planning, Staffing,<br/>Recipe Testing
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem' href='https://www.instagram.com/girirajcafelko/'>
                    <a href='https://www.instagram.com/girirajcafelko/' target="_blank" rel="noreferrer">
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + '/the_grand_gr_cafe.webp'} />
                    </a>
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>
                            <a href='https://www.instagram.com/girirajcafelko/' target="_blank" rel="noreferrer">
                            <span>The Grand GR Cafe </span><i className="bi bi-instagram color-red"></i> 
                            </a><br/>
                        </Card.Title>
                        <Card.Text>
                            Menu Planning, Kitchen and BOH Planning,<br/>Staffing and Audit
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem' href='https://www.instagram.com/chandelakhajuraho/'>
                    <a href='https://www.instagram.com/chandelakhajuraho/' target="_blank" rel="noreferrer">
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + '/hotel_chandela.webp'} />
                    </a>
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>
                            <a href='https://www.instagram.com/chandelakhajuraho/' target="_blank" rel="noreferrer">
                            <span>Hotel Chandela </span><i className="bi bi-instagram color-red"></i> 
                            </a><br/>
                        </Card.Title>
                        <Card.Text>
                            Smoke House Design, Staffing<br/>Kitchen and BOH Planning
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
          </div>
          );
}

export default Projects;